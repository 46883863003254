import clsx from "clsx";
import type { ReactNode } from "react";
type NewsletterSignUpProps = {
  readonly className?: string;
  readonly children: ReactNode;
};
export function NewsletterSignUp({
  className,
  children
}: NewsletterSignUpProps) {
  return <article className={clsx("grid min-h-[300px] grid-cols-1 gap-x-2 overflow-hidden md:grid-cols-6", className)} data-sentry-component="NewsletterSignUp" data-sentry-source-file="NewsletterSignUp.component.tsx">
      {children}
    </article>;
}