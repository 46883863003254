"use client";

import type { SelfDescribingJson } from "@snowplow/browser-tracker";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { useSnowplowContext } from "@/contexts/snowplow/snowplow.context";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import { captureClientException } from "@/services/logging/capture-client-exception";

type StructEventProps = {
  readonly action: string;
  readonly category: string;
  readonly context: ReadonlyArray<SelfDescribingJson> | null;
  readonly isInView: boolean;
  readonly hasTrackingEnabled: boolean;
};

export function useSnowplowInViewTracking({
  action,
  category,
  context,
  isInView,
  hasTrackingEnabled,
}: StructEventProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isTracked, setIsTracked] = useState(false);
  const consent = useCookiebotConsent();
  useSnowplowContext(); // Make sure snowplow is initialized

  useEffect(() => {
    async function trackEvent() {
      if (
        !consent.submitted || // We cannot track without knowing the users consent
        context === null ||
        !isInView ||
        isTracked ||
        !hasTrackingEnabled
      ) {
        return;
      }
      const { trackStructEvent } = await import(
        /* webpackChunkName: "snowplow-tracker" */ "@snowplow/browser-tracker"
      );
      trackStructEvent({
        action,
        category,
        context: [...context],
      });

      setIsTracked(true);
    }

    trackEvent().catch(captureClientException);
  }, [isInView, isTracked, hasTrackingEnabled, consent]);

  useEffect(() => {
    // On any new page we track if the element comes in view again
    setIsTracked(false);
  }, [pathname, searchParams]);
}
