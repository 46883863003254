import { clsx } from "clsx";
import type { MouseEventHandler, ReactNode } from "react";
type OutlineButtonProps = {
  readonly className?: string;
  readonly disabled?: boolean; // eslint-disable-line react/boolean-prop-naming -- The name should match the official one in HTML.
  readonly onClick?: MouseEventHandler<HTMLButtonElement>;
  readonly children: ReactNode;
  readonly isPending?: boolean;
};
export function OutlineButton({
  className,
  disabled,
  onClick,
  children,
  isPending = false
}: OutlineButtonProps) {
  const isPendingOrDisabled = isPending || disabled;
  return <button className={clsx(className, "base-button base-hover-button border border-silver bg-white text-black", isPending && "!cursor-default")} disabled={isPendingOrDisabled} type="button" onClick={onClick} data-sentry-component="OutlineButton" data-sentry-source-file="OutlineButton.component.tsx">
      {children}
    </button>;
}