"use client";

import { useEffect, useState } from "react";
import type { DomId } from "./ads";

type AdSlot = {
  readonly domId: DomId;
  readonly onBeforeShow?: (isEmpty: boolean) => void;
};

export function useAdSlot({ domId, onBeforeShow }: AdSlot) {
  const [isAdEmpty, setIsAdEmpty] = useState<boolean>(true);

  useEffect(() => {
    // eslint-disable-next-line functional/immutable-data, @typescript-eslint/strict-boolean-expressions -- this is the recommended setup to avpid googtag to be undefined
    window.googletag = window.googletag || { cmd: [] };
    const { googletag } = window;

    function slotRenderEndedHandler({
      slot,
      isEmpty,
    }: {
      readonly slot: googletag.Slot;
      readonly isEmpty: boolean;
    }) {
      const currentSlotId = slot.getSlotElementId();

      if (currentSlotId !== domId) {
        return;
      }

      setIsAdEmpty(isEmpty);
      onBeforeShow?.(isEmpty);
    }

    googletag.cmd.push(() => {
      googletag
        .pubads()
        .addEventListener("slotRenderEnded", slotRenderEndedHandler);
    });

    return function cleanUp() {
      googletag.cmd.push(() => {
        googletag
          .pubads()
          .removeEventListener("slotRenderEnded", slotRenderEndedHandler);
      });
    };
  }, []);

  return {
    isAdEmpty,
  };
}
